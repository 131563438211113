.cap-roman {
  list-style-type: upper-roman;
}

.number {
  list-style-type: decimal;
}

.small-roman {
  list-style-type: lower-roman;
}

.small-text {
  list-style-type: lower-alpha;
}

.circle {
  list-style-type: circle;
}
