@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@1,900&family=Lexend:wght@300;400;500;600;700;800;900&display=swap");

* {
  font-family: "Lexend";
}

@layer base {
  html {
    font-family: "Lexend";
  }
}

.gradient_txt {
  background: -webkit-linear-gradient(-90deg, #0079c0 0%, #007f3d 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.textarea {
  resize: none;
}
