.text-bg-image {
  min-height: 80px;
  /* Set a minimum height */
  /* height: 100%; */
  /* Use 100% height for the container */
  background: url('../../assets/images/mediaTextBgImage.png') center center no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1024px) {
  .text-bg-image {
    min-height: 60px;
    /* background-size: cover; */
  }
}

@media only screen and (max-width: 640px) {
  .text-bg-image {
    min-height: 50px;
    /* background-size: cover; */
  }
}

.description-container {
  max-height: calc(1.5em * 4);
  /* 1.5em is the line height */
  overflow: hidden;
  position: relative;
}

.description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  /* Number of lines to show */
  -webkit-box-orient: vertical;
  margin: 0;
  /* Reset margin to prevent extra space at the bottom */
}